"use strict";
/**
 * Days dictionary.
 */
const DaysDictionary = {
    sun: 0,
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6
};
module.exports = {
    /**
     * Put a character in a string using position.
     * @param {number} position
     * @param {*} char
     * @param {string} str
     */
    spliceIntoPosition(position, char, str = undefined) {
        if (str === undefined) {
            str = this.minute();
        }
        let def = str.split(" ");
        def.splice(position, 1, char);
        return def.join(" ");
    },
    /**
     * Get cron minute string.
     */
    minute() {
        return "* * * * *";
    },
    /**
     * Get cron hour String.
     */
    hour() {
        return "0 * * * *";
    },
    /**
     * Get Cron day string, with option of setting: `hourOfTheDay and minuteOfTheHour`
     * @param hourOfTheDay
     * @param minuteOfTheHour
     */
    day(hourOfTheDay = 0, minuteOfTheHour = 0) {
        return `${minuteOfTheHour} ${hourOfTheDay} * * *`;
    },
    /**
     * Get the integer representation of day string.
     * @param {string|number} day
     * @return {number}
     * @example
     *  Helpers.dayToInt('sunday') // 0
     *  Helpers.dayToInt('monday') // 1
     */
    dayToInt(day) {
        if (typeof day === "number")
            return day;
        day = day.trim().toLowerCase();
        if (!DaysDictionary.hasOwnProperty(day))
            throw Error(`Day: "${day}" is not a valid day.`);
        return DaysDictionary[day];
    },
    /**
     * Get the integer representation of multiple day strings.
     * @param days
     */
    daysToIntegers(days) {
        const newDays = [];
        for (const day of days) {
            if (typeof day === "string") {
                newDays.push(this.dayToInt(day));
            }
            else {
                newDays.push(day);
            }
        }
        return newDays;
    },
    /**
     * Checks if startDay and endDay follows calendar sequence.
     * else throws error.
     * @param startDay
     * @param endDay
     */
    validateStartToEndDay(startDay, endDay) {
        if (startDay > endDay)
            throw Error("startDay must come before endDay following normal calendar sequence.");
    }
};
